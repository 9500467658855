import { FunctionComponent } from "react";
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	SelectChangeEvent,
	Button,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "@s6e/spicify-api-sdk-js";
import { changeLanguage, selectLanguage } from "store/reducers/language/languageReducer";
import ContentPage from "components/common/ContentPage/ContentPage";
import { pages } from "pages";
import styled from "@emotion/styled";
import { errorString } from "../../../helpers/api";
import { useGetUserQuery } from "../../../store/apis/auth";
import { useNavigate } from "react-router-dom";

const ProfilePane = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: '0.5em',
	margin: '0.5em',
})

const ProfileSection = styled('div')({
	margin: '0 auto',
	width: 320,
	padding: '0.5em',
})

const ProfilePage: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { data: user, isLoading, error } = useGetUserQuery();
	const language = useAppSelector(selectLanguage);

	const handleLanguageChange = (e: SelectChangeEvent<any>) => {
		dispatch(
			changeLanguage(
				e.target.value as string
			)
		);
	};

	const handleSignOut = () => {
		api.logout();
		navigate(pages.main.url());
	};

	return (
		<ContentPage
			loading={isLoading}
			error={errorString(intl, error)}
			title={intl.formatMessage({ id: "app.profile" })}
			closeLink={pages.main.url()}
		>
			<ProfilePane>
				<ProfileSection>
					<h4>{intl.formatMessage({ id: "app.username" })}</h4>
					<span>{user?.username}</span>
				</ProfileSection>
				<ProfileSection>
					<h4>{intl.formatMessage({ id: "app.email" })}</h4>
					<span>{user?.email}</span>
				</ProfileSection>

				<FormControl
					style={{ width: 320, margin: "20px auto 0 auto" }}
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.language" })}
					</InputLabel>
					<Select
						value={language.locale}
						onChange={handleLanguageChange}
						label={intl.formatMessage({
							id: "app.language"
						})}
						style={{ width: "100%" }}
					>
						<MenuItem value="en_GB">
							{intl.formatMessage({ id: "app.english" })}
						</MenuItem>
						<MenuItem value="pl">
							{intl.formatMessage({ id: "app.polish" })}
						</MenuItem>
					</Select>
				</FormControl>

				<ProfilePane>
					<Button
						startIcon={<PowerSettingsNewIcon />}
						color="secondary"
						size="large"
						onClick={handleSignOut}
					>
						{intl.formatMessage({ id: "app.logout" })}
					</Button>
				</ProfilePane>
			</ProfilePane>
		</ContentPage>
	);
};

export default injectIntl(ProfilePage);
