import { CircularProgress } from "@mui/material";
import { pages } from "pages";
import { FunctionComponent, useEffect } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Navigate } from "react-router";
import { toast } from "react-toastify";
import { useGetWorkspacesQuery } from "../../../store/apis/workspace";
import { errorString } from "../../../helpers/api";

const MainPage: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const { data: workspaces, isLoading, error } = useGetWorkspacesQuery()
	
	useEffect(() => {
		if (error) {
			toast.error(errorString(intl, error));
		}
	}, [ error, intl ]);

	if (isLoading) {
		return <CircularProgress />;
	}

	const personalWorkspace = workspaces?.find(w => w.personal);
	if (personalWorkspace?.id) {
		return <Navigate to={pages.workspace.url(personalWorkspace.id)} />
	}

	return <></>;
}

export default injectIntl(MainPage);