import { Share, ShareCreate, Shares, ShareUpdate } from "@s6e/spicify-api-sdk-js";
import api from "@s6e/spicify-api-sdk-js";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { extractErrors } from "store/common";

interface ShareParams {
	workspaceId: number;
	shareId: number;
}

interface CreateShareParams {
	workspaceId: number,
	data: ShareCreate
}

interface UpdateShareParams {
	workspaceId: number,
	shareId: number,
	data: ShareUpdate
}

const cacheType: "Share" = "Share";
export const shareApi = createApi({
	reducerPath: 'shareApi',
	baseQuery: fakeBaseQuery(),
	tagTypes: [cacheType],
	endpoints: (builder) => ({
		getShares: builder.query<Shares, number>({
			queryFn: async (workspaceId) => {
				try {
					const shares = await api.shares.getShares(workspaceId);
					return { data: shares }
				} catch (error) {
					return extractErrors(error);
				}
			},
			providesTags: (result = []) => [
				cacheType,
				...result.map(({ id }) => ({ type: cacheType, id }))
			]
		}),
		getShare: builder.query<Share, ShareParams>({
			queryFn: async (params) => {
				try {
					const { workspaceId, shareId } = params;
					const share = await api.shares.getShare(workspaceId, shareId);
					return { data: share }
				} catch (error) {
					return extractErrors(error);
				}
			},
			providesTags: (result) => result ? [{ type: cacheType, id: result.id }] : [],
		}),
		createShare: builder.mutation<Share, CreateShareParams>({
			queryFn: async (params: CreateShareParams) => {
				try {
					const { workspaceId, data } = params;
					const share = await api.shares.createShare(workspaceId, data);
					return { data: share }
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: [{ type: cacheType }]
		}),
		updateShare: builder.mutation<Share, UpdateShareParams>({
			queryFn: async (params: UpdateShareParams) => {
				try {
					const { workspaceId, shareId, data } = params;
					const share = await api.shares.updateShare(workspaceId, shareId, data);
					return { data: share };
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: (result) => result ? [
				cacheType,
				{ type: cacheType, id: result.id }
			] : []
		}),
		deleteShare: builder.mutation<number, ShareParams>({
			queryFn: async (params: ShareParams) => {
				const { workspaceId, shareId } = params;
				try {
					await api.shares.deleteShare(workspaceId, shareId);
					return { data: shareId };
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: (result) => result ? [
				cacheType,
				{ type: cacheType, id: result }
			] : []
		}),
	}),
});

export const {
	useGetSharesQuery,
	useGetShareQuery,
	useCreateShareMutation,
	useUpdateShareMutation,
	useDeleteShareMutation,
} = shareApi