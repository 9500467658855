import { FunctionComponent, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { toast } from "react-toastify";
import Logo from "components/common/Logo/Logo";
import LoadingButton from "components/common/LoadingButton/LoadingButton";

import "./login.css";
import { ResetPassword } from "@s6e/spicify-api-sdk-js";
import { StyledTextField } from "theme/styledComponents";
import { pages } from "pages";
import { errorString } from "helpers/api";
import ContentError from "components/common/ContentPage/ContentError";
import { useResetPasswordMutation } from "../../../store/apis/auth";
import { confirmPasswordValidation, passwordValidation } from "../../../validators";


type PasswordResetStep2Params = {
	code?: string;
};

type PasswordResetStep2FormSubmit = (payload: FieldValues) => Promise<void>;

const PasswordResetStep2: FunctionComponent<WrappedComponentProps> = ({
	intl
}) => {
	
	const { code } = useParams<PasswordResetStep2Params>();
	const {
		register,
		formState: { errors },
		handleSubmit,
		watch
	} = useForm();

	const [ resetPassword, { error, isLoading, isSuccess} ] = useResetPasswordMutation();

	const navigate = useNavigate();

	const submitForm: PasswordResetStep2FormSubmit = async ({ password }) => {
		if (code) {
			const payload: ResetPassword = {
				code,
				plain_password: password
			};
			resetPassword(payload);
		}
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success(
				`🙌 ${intl.formatMessage({
					id: "app.successResetPassword"
				})}`
			);
			navigate(pages.login.url());
		}
	}, [isSuccess, intl, navigate]);

	return (
		<div className="password-reset-wrapper">
			<Logo />
			<form
				className="password-reset-form"
				onSubmit={handleSubmit(submitForm)}
			>
				<StyledTextField
					label={intl.formatMessage({ id: "app.password" })}
					type="password"
					{...register("password", passwordValidation(intl))}
					helperText={errors.password?.message?.toString() || ''}
					error={!!errors.password}
				/>
				<StyledTextField
					label={intl.formatMessage({ id: "app.confirmPassword" })}
					type="password"
					{...register("confirmPassword", confirmPasswordValidation(intl, watch))}
					helperText={errors.confirmPassword?.message?.toString() || ''}
					error={!!errors.confirmPassword}
				/>
				<ContentError error={errorString(intl, error)} />
				<LoadingButton
					isLoading={isLoading}
					size={25}
					type="submit"
					label={intl.formatMessage({ id: "app.reset" })}
				/>
			</form>
		</div>
	);
};

export default injectIntl(PasswordResetStep2);
