import { FunctionComponent } from "react"
import { DishIconProps } from "./DishIconProps";

const DishIconLong: FunctionComponent<DishIconProps> = ({
    color = '#4a1971',
    width = 35,
    height = 35,
}) => {
  

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 41" width={width} height={height}>
      <g data-name="Group 19" transform="translate(-112.54 -541.23)">
        <path
          fill={color}
          d="M131.04 578.23a13.49 13.49 0 01-13.5-13.311h13.689V551.23a13.5 13.5 0 019.356 23.046 13.458 13.458 0 01-9.545 3.954z"
          data-name="Subtraction 1"
        ></path>
        <g
          fill="none"
          stroke={color}
          strokeWidth="2"
          data-name="Ellipse 2"
          transform="translate(112.54 547.23)"
        >
          <ellipse cx="18" cy="17.5" stroke="none" rx="18" ry="17.5"></ellipse>
          <ellipse cx="18" cy="17.5" rx="17" ry="16.5"></ellipse>
        </g>
        <rect
          width="10"
          height="4"
          fill={color}
          data-name="Rectangle 66"
          rx="2"
          transform="translate(125.54 541.23)"
        ></rect>
        <rect
          width="4.836"
          height="2.046"
          fill={color}
          data-name="Rectangle 68"
          rx="1.023"
          transform="rotate(-30 1082.886 55.659)"
        ></rect>
        <rect
          width="4.836"
          height="2.046"
          fill={color}
          data-name="Rectangle 72"
          rx="1.023"
          transform="rotate(30 -949.986 533.588)"
        ></rect>
        <path
          fill={color}
          d="M0 0H4V4H0z"
          data-name="Rectangle 67"
          transform="translate(128.54 545.23)"
        ></path>
        <path
          fill={color}
          d="M0 0H2.554V2.554H0z"
          data-name="Rectangle 69"
          transform="rotate(-30 1085.46 52.548)"
        ></path>
        <path
          fill={color}
          d="M0 0H2.554V2.554H0z"
          data-name="Rectangle 71"
          transform="rotate(-150 144.308 257.143)"
        ></path>
      </g>
    </svg>
  );
}

export default DishIconLong;
