import { FunctionComponent, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Logo from "components/common/Logo/Logo";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";

import "./register.css";
import { useConfirmEmailMutation } from "../../../store/apis/auth";


type ConfirmParams = {
	code?: string;
};

const Confirm: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	
	const { code } = useParams<ConfirmParams>();

	const navigate = useNavigate();

	const [ confirmEmail, { isUninitialized, isSuccess, isLoading, error } ] = useConfirmEmailMutation();

	useEffect(() => {
		if (code && isUninitialized) {
			confirmEmail({ code });
		}
	}, [code, isUninitialized, confirmEmail]);

	useEffect(() => {
		if (isSuccess) {
			toast.success(
				`🙌 ${intl.formatMessage({ id: "app.successConfirm" })}`
			);
			navigate(pages.login.url());
		}
	})

	const errorMessage = !code
		? intl.formatMessage({ id: "app.missingConfirmationCode" })
		: errorString(intl, error);
	return (
		<div className="confirmation-wrapper">
			<Logo />

			<ContentError error={errorMessage} />

			{isLoading && (
				<div style={{ marginTop: 20, textAlign: "center" }}>
					<CircularProgress />
				</div>
			)}
		</div>
	);
};

export default injectIntl(Confirm);
