import api from "@s6e/spicify-api-sdk-js";

export enum StoreStatus {
    Idle = 'idle',
    InProgress = 'loading',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

export type StoreError = string;

export function extractErrors(error: any) {
    console.log(error);
    if (api.isApiError(error)) {
        return { error: String(error.status) }
    }

    if (error.response) {
        return { error: String(error.response.status) }
    }

    if (error.request) {
        return { error: "app.somethingWentWrong" }
    }

    return { error };
}