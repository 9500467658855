import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContentPage from "components/common/ContentPage/ContentPage";
import { Dish } from "@s6e/spicify-api-sdk-js";
import {
    Mode
} from "store/reducers/dishes/dishesReducer";
import DishList from "components/common/DishList/DishList";
import TabNavigation from "../TabNavigation";
import { pages } from "pages";
import { injectIntl, WrappedComponentProps } from "react-intl";
import {
    useDeleteDishMutation,
    useGetDishesQuery, useSetDishFavoriteMutation, useSetDropDishMutation,
    useSetUseDishMutation, useUnsetDishFavoriteMutation
} from "../../../../../store/apis/dish";

type AZDishesProps = {
	workspaceId: number;
    mode: Mode
};

const Dishes: FunctionComponent<AZDishesProps & WrappedComponentProps> = ({ workspaceId, mode }) => {
	const navigate = useNavigate();

    const { data: dishes, isLoading }  = useGetDishesQuery(workspaceId);

    const [ deleteDish] = useDeleteDishMutation();
    const [ setUseDish, { isSuccess: dishUsed, originalArgs: useDishParams }] = useSetUseDishMutation();
    const [ setDropDish] = useSetDropDishMutation();
    const [ setDishFavorite] = useSetDishFavoriteMutation();
    const [ unsetDishFavorite] = useUnsetDishFavoriteMutation();

    const onDeleteDishClick = async (dish: Dish) => {
        deleteDish({
            workspaceId,
            dishId: dish.id
        });
    }

    // useEffect(() => {
    //     if (dishes) {
    //         setDishList(sortDishes(dishes, mode, ranking))
    //     }
    // }, [ dishUsed, dishes ]);

    const onUseDishClick = async (dish: Dish) => {
        setUseDish({ workspaceId, dishId: dish.id });
    }
    useEffect(() => {
        if (dishUsed && useDishParams) {
            navigate(pages.workspace.dishView.url(workspaceId, useDishParams.dishId));
        }
    }, [ dishUsed, useDishParams, navigate, workspaceId ]);

    const onDropDishClick = async (dish: Dish) => {
        setDropDish({ workspaceId, dishId: dish.id })
    };

    const onEditDishClick = async (dish: Dish) => {
        navigate(pages.workspace.dishEdit.url(workspaceId, dish.id));
    }

    const onToggleFavoriteClick = async (dish: Dish) => {
        if (dish.favorite) {
			unsetDishFavorite({ workspaceId, dishId: dish.id });
		} else {
			setDishFavorite({ workspaceId, dishId: dish.id });
		}
    }
    
    const viewDish = async (dish: Dish) => {
        navigate(pages.workspace.dishView.url(workspaceId, dish.id));
    }

    const onNavChange = (mode: Mode) => {
        navigate(pages.workspace.url(workspaceId, mode));
    };

    const onAddDishClick = () => {
        navigate(pages.workspace.dishCreate.url(workspaceId));
    };

	return (
        <>
            <TabNavigation value={mode} onChange={onNavChange} />
            <ContentPage
                loading={isLoading}
                filterLink={mode !== Mode.Suggested ? "filter" : undefined}
                settingsLink={mode === Mode.Suggested ? "settings" : undefined}
                // error={errorString(intl, fetchRankingError)}
            >
                {dishes && (
                    <DishList
                        dishes={dishes}
                        onDishDeleteClick={onDeleteDishClick}
                        onDishUseClick={onUseDishClick}
                        onDishDropClick={onDropDishClick}
                        onDishEditClick={onEditDishClick}
                        onDishFavoriteClick={onToggleFavoriteClick}
                        onDishNameClick={viewDish}
                        onAddDishClick={onAddDishClick}
                    />)}
            </ContentPage>
        </>
	);
};

export default injectIntl(Dishes);
