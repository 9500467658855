import logoIcon from "assets/favicon.svg";
import { LogoWrapper } from "components/composed/LogoWrapper";

const Logo = () => {
	return <LogoWrapper>
		<img src={logoIcon} alt="logo" />
	</LogoWrapper>;
};

export default Logo;
