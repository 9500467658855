import { FunctionComponent } from "react"
import { DishIconProps } from "./DishIconProps";

const DishIconMedium: FunctionComponent<DishIconProps> = ({
    color = '#4a1971',
    width = 35,
    height = 35,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 41" width={width} height={height}>
      <g data-name="Group 30" transform="translate(-40 -325)">
        <path
          fill={color}
          d="M59 361.991v-26.983a13.414 13.414 0 014.878 1.1 13.467 13.467 0 014.23 2.9 13.459 13.459 0 012.849 4.27A13.407 13.407 0 0172 348.5a13.417 13.417 0 01-1.061 5.255 13.458 13.458 0 01-2.893 4.292 13.46 13.46 0 01-4.291 2.893A13.383 13.383 0 0159 361.991z"
          data-name="Subtraction 3"
        ></path>
        <g
          fill="none"
          stroke={color}
          strokeWidth="2"
          data-name="Ellipse 2"
          transform="translate(40 331)"
        >
          <ellipse cx="18" cy="17.5" stroke="none" rx="18" ry="17.5"></ellipse>
          <ellipse cx="18" cy="17.5" rx="17" ry="16.5"></ellipse>
        </g>
        <rect
          width="10"
          height="4"
          fill={color}
          data-name="Rectangle 66"
          rx="2"
          transform="translate(53 325)"
        ></rect>
        <rect
          width="4.836"
          height="2.046"
          fill={color}
          data-name="Rectangle 68"
          rx="1.023"
          transform="rotate(-30 643.127 82.901)"
        ></rect>
        <rect
          width="4.836"
          height="2.046"
          fill={color}
          data-name="Rectangle 72"
          rx="1.023"
          transform="rotate(30 -582.763 290.119)"
        ></rect>
        <path
          fill={color}
          d="M0 0H4V4H0z"
          data-name="Rectangle 67"
          transform="translate(56 329)"
        ></path>
        <path
          fill={color}
          d="M0 0H2.554V2.554H0z"
          data-name="Rectangle 69"
          transform="rotate(-30 645.704 79.778)"
        ></path>
        <path
          fill={color}
          d="M0 0H2.554V2.554H0z"
          data-name="Rectangle 71"
          transform="rotate(-150 79.07 158.747)"
        ></path>
      </g>
    </svg>
  );
}

export default DishIconMedium;
