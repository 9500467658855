import { FunctionComponent, ReactNode } from "react";
import {
	BottomNavigation,
	BottomNavigationAction,
	Paper
} from "@mui/material";
import { NavLink } from "react-router-dom";

type NavigationItem = {
    icon: ReactNode;
    href: string;
    text: string;
};

type NavigationItems = NavigationItem[];

type MobileNavigationProps = {
	listItems: NavigationItems;
    height?: number;
};

const MobileNavigation: FunctionComponent<MobileNavigationProps> = ({
	listItems,
    height = 50
}) => {
    return (
        <Paper sx={{ height: height, position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation showLabels>
                {listItems.map(({ text, icon, href }) => (
                    <BottomNavigationAction
                        key={text}
                        component={NavLink}
                        to={href}
                        label={text}
                        icon={icon}
                    />
                ))}
            </BottomNavigation>
        </Paper>
    );

};

export default MobileNavigation;
