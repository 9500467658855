import React from "react";
import { createRoot } from 'react-dom/client';

import Bootstrap from "./Bootstrap";
import { Provider } from "react-redux";
import store from "store/store";

import "./index.css";
import configureApi from "config/api/apiConfig";
import api from "@s6e/spicify-api-sdk-js";

configureApi(store);

api.rebuild_session();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Bootstrap />
		</Provider>
	</React.StrictMode>,
);
