import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { injectIntl, WrappedComponentProps } from "react-intl";
import Logo from "components/common/Logo/Logo";
import TextWithLink from "components/common/TextWithLink/TextWithLink";
import LoadingButton from "components/common/LoadingButton/LoadingButton";
import "./login.css";
import { Credentials } from "@s6e/spicify-api-sdk-js";
import { StyledTextField } from "theme/styledComponents";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";
import { useLoginMutation } from "../../../store/apis/auth";


const Login: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const navigate = useNavigate();

	const [ login, { isSuccess, isLoading, error } ] = useLoginMutation()

	const {
		register,
		formState: { errors },
		handleSubmit
	} = useForm();

	const submitForm = async (payload: FieldValues) => {
		await login(payload as Credentials);
	};

	useEffect(() => {
		if (isSuccess) {
			navigate(pages.main.url());
		}
	}, [ isSuccess, navigate ])

	return (
		<div className="login-wrapper">
			<Logo />
			<form className="login-form" onSubmit={handleSubmit(submitForm)}>
				<StyledTextField
					{...register("username", {
						required: intl.formatMessage({ id: "app.usernameIsRequired" })
					})}
					label={intl.formatMessage({ id: "app.username" })}
					helperText={errors.username?.message?.toString() || ''}
					error={!!errors.username}
				/>
				<StyledTextField
					{...register("password", {
						required: intl.formatMessage({ id: "app.passwordIsRequired" })
					})}
					label={intl.formatMessage({ id: "app.password" })}
					type="password"
					helperText={errors.password?.message?.toString() || ''}
					error={!!errors.password}
				/>
				<ContentError error={errorString(intl, error)} />
				<LoadingButton
					isLoading={isLoading}
					size={25}
					type="submit"
					label={intl.formatMessage({ id: "app.signIn" })}
				/>
			</form>
			<TextWithLink
				text={intl.formatMessage({ id: "app.dontHaveAccount" })}
				linkHref={pages.register.url()}
				buttonText={intl.formatMessage({ id: "app.signUp" })}
			/>
			<TextWithLink
				text={intl.formatMessage({ id: "app.forgotPassword" })}
				linkHref={pages.resetPassword.url()}
				buttonText={intl.formatMessage({ id: "app.reset" })}
			/>
		</div>
	);
};

export default injectIntl(Login);
