import {
	createSlice,
	PayloadAction
} from "@reduxjs/toolkit";
import { DefaultGenerateQuery, GenerateQuery, Ranking } from "@s6e/spicify-api-sdk-js";
import { StoreError, StoreStatus } from "store/common";
import { RootState } from "store/store";

interface GeneratorState {
	query: GenerateQuery;
	ranking?: Ranking;
	fetchStatus: StoreStatus;
  	fetchError?: StoreError;
}

const initialState: GeneratorState = {
	query: DefaultGenerateQuery,
	ranking: undefined,
	fetchStatus: StoreStatus.Idle,
  	fetchError: undefined,
};

export const selectQuery = (state: RootState): GenerateQuery => state.generator.query;

export const generatorSlice = createSlice({
	name: "generator",
	initialState,
	reducers: {
		setQuery: (state, action: PayloadAction<GenerateQuery>) => {
			state.query = action.payload;
		}
	}
});

export const { setQuery } = generatorSlice.actions;

const generatorReducer = generatorSlice.reducer;

export default generatorReducer;
