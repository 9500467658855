import { Fragment, FunctionComponent } from "react";
import { Typography, IconButton, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { getAccessTransId } from "helpers/share";
import EditIcon from "@mui/icons-material/Edit";
import { Shares } from "@s6e/spicify-api-sdk-js";
import { StyledListItem } from "theme/styledComponents";

type ShareEmailListProps = {
	shares: Shares;
	setEditShareDialogOpened: (isOpen: boolean) => void;
	setChoosenShareId: (shareId: number) => void;
	setDeleteShareDialogOpened: (isOpen: boolean) => void;
};

const ShareEmailList: FunctionComponent<
	ShareEmailListProps & WrappedComponentProps
> = ({
	intl,
	shares,
	setEditShareDialogOpened,
	setChoosenShareId,
	setDeleteShareDialogOpened
}) => {
	return (
		<ul>
			{shares.map((share) => {
				return (
					<Fragment key={share.id}>
						<StyledListItem>
							<div
								style={{
									display: "flex",
									width: "55%"
								}}
							>
								<Typography variant="subtitle2" noWrap>
									{share.owner.display_name}
								</Typography>
							</div>

							<Typography variant="subtitle2">
								{intl.formatMessage({
									id: getAccessTransId(share.access_level)
								})}
							</Typography>

							<div>
								<IconButton
									onClick={() => {
										setChoosenShareId(share.id);
										setEditShareDialogOpened(true);
									}}
								>
									<EditIcon color="primary" />
								</IconButton>
								<IconButton
									onClick={() => {
										setChoosenShareId(share.id);
										setDeleteShareDialogOpened(true);
									}}
								>
									<DeleteIcon color="secondary" />
								</IconButton>
							</div>
						</StyledListItem>
						<Divider />
					</Fragment>
				);
			})}
		</ul>
	);
};

export default injectIntl(ShareEmailList);
