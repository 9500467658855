import { FunctionComponent, useEffect, useState } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useParams } from "react-router-dom";
import {
	Typography,
	FormControl,
	FormLabel,
	FormGroup,
	Chip
} from "@mui/material";
import { TagGroups, tagsToFormControls } from "helpers/tags";
import ContentPage from "components/common/ContentPage/ContentPage";
import { pages } from "pages";
import Recipe from "./Recipe";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { getNumberParam } from "helpers/views";
import { useGetDishQuery } from "store/apis/dish";

type DishViewParams = {
	dishId?: string;
};

const DishView: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const params = useParams<DishViewParams>();
	const [ dishId, paramError ] = getNumberParam(params.dishId, "dishId", intl);

	const { data: dish, error, isLoading}  = useGetDishQuery({ workspaceId, dishId: dishId || 0 }, {
		skip: !dishId
	})
	const [formattedTags, setFormattedTags] = useState<TagGroups | null>(null);

	useEffect(() => {
		if (dish) {
			setFormattedTags(tagsToFormControls(dish.tags || []));
		}
	}, [ dish ]);

	return (
		<ContentPage
			title={dish?.name ||  ""}
			editLink={pages.workspace.dishEdit.url(workspaceId, dish?.id || 0)}
			closeLink={pages.workspace.dishes.url(workspaceId)}
			loading={isLoading}
			error={paramError || (typeof error === "string" ? error : undefined)}
		>
			{dish?.recipe && (<Recipe recipe={dish?.recipe} />)}
			{dish?.kilocalories ? (
				<Typography
					variant="body1"
					style={{ marginTop: "0.5rem" }}
				>
					{intl.formatMessage({ id: "app.kilocalories" }) +
						":" +
						` ${dish.kilocalories}`}
				</Typography>
			) : null}
			{formattedTags?.length ? (
				<FormControl component="fieldset" fullWidth>
					<FormLabel
						style={{
							textAlign: "center",
							marginTop: "0.8rem"
						}}
					>
						{intl.formatMessage({ id: "app.tags" })}
					</FormLabel>
					{formattedTags.map((tag) => (
						<FormControl
							aria-label="position"
							key={tag.groupName}
							style={{ margin: "0 auto" }}
						>
							<FormLabel
								style={{
									textAlign: "center",
									marginTop: "0.8rem"
								}}
							>
								{tag.groupName}
							</FormLabel>
							<FormGroup row>
								{tag.values.map((tagValue) => {
									return (
										<Chip
											key={tagValue.name}
											label={
												<Typography
													variant="caption"
													style={{
														whiteSpace:
															"normal"
													}}
												>
													{tagValue.name}
												</Typography>
											}
											size="small"
											style={{
												color: tagValue.font_color,
												backgroundColor:
													tagValue.background_color,
												margin: "0.5rem"
											}}
										/>
									);
								})}
							</FormGroup>
						</FormControl>
					))}
				</FormControl>
			) : null}
		</ContentPage>
	);
};

export default injectIntl(DishView);
