import { FunctionComponent, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { ButtonsWrapper } from "theme/styledComponents";
import {
	TextField,
	Button,
	FormControl,
	FormLabel,
	FormGroup,
	FormControlLabel,
	Checkbox,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Autocomplete
} from "@mui/material";
import ContentPage from "components/common/ContentPage/ContentPage";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Tag, Tags, TimeEffort } from "@s6e/spicify-api-sdk-js";
import { pages } from "pages";
import SubmitButton from "components/common/SubmitButton";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { errorString } from "helpers/api";
import ContentError from "components/common/ContentPage/ContentError";
import { getNumberParam } from "helpers/views";
import { useCreateDishMutation, useGetDishQuery, useUpdateDishMutation } from "../../../../store/apis/dish";
import { useGetTagsQuery } from "../../../../store/apis/tag";


type DishFormParams = {
	dishId: string;
}

type DishFormSubmit = (payload: FieldValues) => void;

const DishForm: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const navigate = useNavigate();

	const params = useParams<DishFormParams>();
	const [ dishId, paramError ] = getNumberParam(params.dishId, "dishId", intl);

	const closeLink = pages.workspace.url(workspaceId);

	const { data: dish, error, isLoading}  = useGetDishQuery({ workspaceId, dishId: dishId || 0 }, {
		skip: !dishId
	});
	const [ updateDish, { isLoading: isUpdating, error: updateError, isSuccess: updateSuccess }] = useUpdateDishMutation();
	const [ createDish, { isLoading: isCreating, error: createError, isSuccess: createSuccess }] = useCreateDishMutation();

	const { data: tags, error: tagsError, isError: isErrorTags, isLoading: isLoadingTags}  = useGetTagsQuery(workspaceId);

	type DishFormSubmitPayload = {
		dishName: string
		timeEffort: TimeEffort,
		recipe: string,
		kilocalories: number,
		spring: boolean,
		summer: boolean,
		autumn: boolean,
		winter: boolean,
		tags: Tags
	};
	
	
	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors }
	} = useForm<DishFormSubmitPayload>({
		shouldUnregister: true,
		defaultValues: {
			dishName: dish?.name || "",
			timeEffort: dish?.time_effort,
			recipe: dish?.recipe || "",
			kilocalories: dish?.kilocalories,
			spring: dish?.spring || false,
			summer: dish?.summer || false,
			autumn: dish?.autumn || false,
			winter: dish?.winter || false,
			tags: dish?.tags || []
		}
	});

	const onSubmit: DishFormSubmit = async ({
		dishName,
		recipe,
		timeEffort,
		kilocalories,
		spring,
		summer,
		autumn,
		winter,
		tags
	}) => {
		const data = {
			name: dishName,
			time_effort: timeEffort,
			recipe,
			spring,
			summer,
			autumn,
			winter,
			kilocalories: Number(kilocalories),
			tags: tags.map((tag: Tag) => tag.id)
		};

		if (dishId) {
			updateDish({ workspaceId, dishId, data });
		} else {
			createDish({ workspaceId, data });
		}
	};

	useEffect(() => {
		if (updateSuccess || createSuccess) {
			navigate(closeLink);
		}
	}, [ updateSuccess, createSuccess, navigate, closeLink ]);


	useEffect(() => {
		if (dish) {
			reset({
				dishName: dish.name,
				timeEffort: dish.time_effort,
				recipe: dish.recipe,
				kilocalories: dish.kilocalories,
				spring: dish.spring,
				winter: dish.winter,
				summer: dish.summer,
				autumn: dish.autumn,
				tags: dish.tags
			});
		}
	}, [dish]);

	return (
		<ContentPage
			title={
				dishId
					? intl.formatMessage({ id: "app.editDish" })
					: intl.formatMessage({ id: "app.addDish" })
			}
			closeLink={closeLink}
			loading={isLoading && !!workspaceId}
			error={errorString(intl, paramError, error)}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<TextField
					{...register("dishName", {
						required: intl.formatMessage({ id: "app.dishNameIsRequired" })
					})}
					fullWidth
					label={intl.formatMessage({ id: "app.name" })}
					helperText={errors.dishName?.message?.toString() || ''}
					error={!!errors.dishName}
					style={{ marginTop: 10 }}
				/>
				<FormControl component="fieldset" fullWidth>
					<FormLabel
						style={{ textAlign: "center", marginTop: "0.8rem" }}
					>
						{intl.formatMessage({ id: "app.season" })}
					</FormLabel>
					<FormGroup
						aria-label="position"
						row
						style={{ margin: "0 auto" }}
					>
						<FormControlLabel
							control={
								<Controller
									name="spring"
									control={control}
									render={({ field: {value, ...props} }) => (
										<Checkbox
											checked={value}
											{...props}
										/>
									)}
								/>
							}
							label={intl.formatMessage({ id: "app.spring" })}
						/>
						<FormControlLabel
							control={
								<Controller
									name="summer"
									control={control}
									render={({ field: {value, ...props} }) => (
										<Checkbox
											checked={value}
											{...props}
										/>
									)}
								/>
							}
							label={intl.formatMessage({ id: "app.summer" })}
						/>
						<FormControlLabel
							control={
								<Controller
									name="autumn"
									control={control}
									render={({ field: {value, ...props} }) => (
										<Checkbox
											checked={value}
											{...props}
										/>
									)}
								/>
							}
							label={intl.formatMessage({ id: "app.autumn" })}
						/>
						<FormControlLabel
							control={
								<Controller
									name="winter"
									control={control}
									render={({ field: {value, ...props} }) => (
										<Checkbox
											checked={value}
											{...props}
										/>
									)}
								/>
							}
							label={intl.formatMessage({ id: "app.winter" })}
						/>
					</FormGroup>
				</FormControl>
				<FormControl
					fullWidth
					error={!!errors.timeEffort}
					style={{ marginTop: "0.8rem" }}
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.timeEffort" })}
					</InputLabel>
					<Controller
						render={({ field }) => (
							<Select
								label={intl.formatMessage({
									id: "app.timeEffort"
								})}
								style={{ textAlign: "left" }}
								{...field}
							>
								<MenuItem value={0}>
									{intl.formatMessage({
										id: "app.short"
									})}
								</MenuItem>
								<MenuItem value={1}>
									{intl.formatMessage({
										id: "app.medium"
									})}
								</MenuItem>
								<MenuItem value={2}>
									{intl.formatMessage({ id: "app.long" })}
								</MenuItem>
							</Select>
						)}
						name="timeEffort"
						control={control}
						rules={{ required: true }}
					/>
					<FormHelperText>
						{errors.timeEffort &&
							intl.formatMessage({ id: "app.emptyField" })}
					</FormHelperText>
				</FormControl>
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.recipe" })}
					{...register("recipe")}
					style={{ marginTop: "0.8rem" }}
				/>
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.kilocalories" })}
					{...register("kilocalories")}
					style={{ marginTop: "0.8rem" }}
					type="number"
					inputProps={{ min: "0", max: "10000", step: "1" }}
					helperText={errors.kilocalories?.message?.toString() || ''}
					error={!!errors.kilocalories}
				/>
				<FormControl component="fieldset" fullWidth>
					<FormLabel
						style={{
							textAlign: "center",
							marginTop: "0.8rem"
						}}
					>
						{intl.formatMessage({ id: "app.tags" })}
					</FormLabel>
					<Controller
						name="tags"
						control={control}
						render={({ field: { value, onChange, ...props} }) => (
							<Autocomplete
								multiple
								value={value}
								onChange={(_event, selectedOptions) => {
								  onChange(selectedOptions);
								}}
								{...props}
								options={tags || []}
								loading={isLoadingTags}
								noOptionsText={intl.formatMessage({
									id: "app.noOptions"
								})}
								getOptionLabel={(option: Tag) => option.name}
								isOptionEqualToValue={(option, value) =>
									option.id === value.id
								}
								groupBy={(option) => option.group}
								renderInput={(params) => (
									<TextField
										{...params}
										label={intl.formatMessage({
											id: "app.chooseTag"
										})}
										error={isErrorTags}
										helperText={errorString(intl, tagsError)}
										fullWidth
									/>
								)}
							/>							
						)}
					/>
				</FormControl>
				<ContentError error={errorString(intl, updateError)} />
				<ContentError error={errorString(intl, createError)} />
				<ButtonsWrapper>
					<Button
						color="secondary"
						variant="contained"
						component={Link}
						to={closeLink}
						style={{ marginRight: 10 }}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton
						inProgress={isCreating || isUpdating}
						caption={intl.formatMessage({ id: "app.save" })}
					/>
				</ButtonsWrapper>
			</form>
		</ContentPage>
	);
};

export default injectIntl(DishForm);
