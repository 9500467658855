import { FunctionComponent } from "react";
import { useForm, Controller, FieldValues } from "react-hook-form";
import {
	TextField,
	FormControl,
	Select,
	MenuItem,
	IconButton
} from "@mui/material";
import { shareAccessLevel } from "consts";
import { injectIntl, WrappedComponentProps } from "react-intl";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { useCreateShareMutation } from "../../../../../store/apis/share";
import { emailValidation } from "../../../../../validators";

const StyledForm = styled("form")(({ theme }) => ({
	display: "flex",
	[theme.breakpoints.down("md")]: {
		flexDirection: "column"
	}
}));

const AccessSelectFormControl = styled(FormControl)(({ theme }) => ({
	marginLeft: "1rem",
	flexGrow: 1,
	[theme.breakpoints.down("md")]: {
		marginLeft: 0,
		marginTop: "0.5rem"
	}
}));

const ButtonWrapper = styled("div")(({ theme }) => ({
	marginTop: "0.3rem",
	[theme.breakpoints.down("md")]: {
		marginTop: "0.7rem"
	}
}));

type SubmitEmailFormSubmit = (payload: FieldValues) => Promise<void>;

const ShareEmailForm: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({
	workspaceId,
	intl
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		control
	} = useForm({ shouldUnregister: true });

	const [ createShare, { isLoading } ] = useCreateShareMutation();

	const submitEmailForm: SubmitEmailFormSubmit = async ({
		email,
		accessLevel	
	}) => {
		createShare({
			workspaceId,
			data: {
				emails: [email],
				access_level: accessLevel
			}
		});
	};

	return (
		<StyledForm onSubmit={handleSubmit(submitEmailForm)}>
			<TextField
				label={intl.formatMessage({
					id: "app.putEmailToSend"
				})}
				{...register("email", emailValidation(intl))}
				helperText={errors.email?.message?.toString() || ''}
				error={!!errors.email}
				style={{ flexGrow: 1 }}
			/>
			<div
				style={{
					display: "flex",
					flexGrow: 1
				}}
			>
				<AccessSelectFormControl>
					<Controller
						render={({ field }) => (
							<Select {...field}>
								{shareAccessLevel.map((level) => (
									<MenuItem
										key={level.value}
										value={level.value}
									>
										{intl.formatMessage({
											id: level.translationId
										})}
									</MenuItem>
								))}
							</Select>
						)}
						control={control}
						name="accessLevel"
						rules={{ required: true }}
						defaultValue={1}
					/>
				</AccessSelectFormControl>
				<ButtonWrapper>
					<IconButton
						disabled={isLoading}
						type="submit"
						style={{
							marginLeft: "1rem"
						}}
					>
						<AddIcon />
					</IconButton>
				</ButtonWrapper>
			</div>
		</StyledForm>
	);
};

export default injectIntl(ShareEmailForm);
