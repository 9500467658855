import { Dish, Dishes } from "@s6e/spicify-api-sdk-js";
import { Rank, Ranking } from "@s6e/spicify-api-sdk-js";

export enum Mode {
    Suggested = "suggested",
    Favorites = "favorites",
    Dishes = "dishes"
}

function compareDishes(dish: Dish, other: Dish): number {
	return dish.name.localeCompare(other.name);
}

function compareByRanking(ranking: Ranking): (dish: Dish, other: Dish) => number {
	const rankingIds = ranking.map((r: Rank) => r.id);
	return (dish: Dish, other: Dish) => rankingIds.indexOf(dish.id) - rankingIds.indexOf(other.id);
}

function filterByRanking(ranking: Ranking): (dish: Dish) => boolean {
	const rankingIds = ranking.map((r: Rank) => r.id);
	return (dish: Dish) => rankingIds.indexOf(dish.id) > -1;
}

export const sortDishes = (dishes: Dishes, mode: Mode, ranking: Ranking | undefined = undefined): Dishes => {
	if (dishes.length === 0) {
		return [];
	}

	let result = dishes.map((d: Dish) => d);
	if (mode === Mode.Favorites || mode === Mode.Dishes) {
		result.sort(compareDishes);
		if (mode === Mode.Favorites) {
			return result.filter((d: Dish) => d.favorite)
		}
		return result;
	}

	if (!ranking) {
		return [];
	}

	result = result.filter(filterByRanking(ranking))
	result.sort(compareByRanking(ranking));

	return result;
}
