import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";


interface AuthState {
	isAuthenticated: boolean | null;
}

const initialState: AuthState = {
	isAuthenticated: null,
};

export const selectIsAuthenticated = (state: RootState): boolean => state.auth.isAuthenticated;

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		signIn: (state) => {
			state.isAuthenticated = true;
		},
		signOut: (state) => {
			state.isAuthenticated = false;
		}
	}
});
const authReducer = authSlice.reducer;

export const { signIn, signOut } = authSlice.actions;

export default authReducer;
