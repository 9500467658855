import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Typography, Divider, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ShareLink from "./ShareLink/ShareLink";
import ShareEmail from "./ShareEmail/ShareEmail";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { useGetWorkspaceQuery } from "../../../store/apis/workspace";
import { errorString } from "../../../helpers/api";


const PageTitleWrapper = styled("div")(({ theme }) => ({
	flexBasis: "50%",
	[theme.breakpoints.down("md")]: {
		flexBasis: "100%",
		marginTop: "3rem"
	}
}));

const Main = styled("main")(({ theme }) => ({
	display: "flex",
	margin: "1rem 0",
	[theme.breakpoints.down("md")]: {
		flexDirection: "column"
	}
}));

const ShareLinksWrapper = styled("div")({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	marginBottom: "0.5rem"
});

const SharePage: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({
	workspaceId,
	intl
}) => {
	const navigate = useNavigate();
	const { data: workspace, error } = useGetWorkspaceQuery(workspaceId);

	useEffect(() => {
		if (workspace?.personal) {
			navigate(pages.main.url());
			return;
		}
	}, [ workspace, navigate ]);

	if (error) {
		return <ContentError error={errorString(intl, error)} />
	}

	return (
		<>
			<PageTitleWrapper>
				<Typography variant="h5">
					{intl.formatMessage({ id: "app.share" })}:
				</Typography>
				<IconButton onClick={_ => navigate(-1)}>
					<CloseIcon />
				</IconButton>
			</PageTitleWrapper>
			<Divider />
			<Main>
				<ShareEmail workspaceId={workspaceId} />
				<ShareLinksWrapper>
					<ShareLink workspaceId={workspaceId} />
				</ShareLinksWrapper>
			</Main>
		</>
	);
};

export default injectIntl(SharePage);
