import { Workspace, WorkspaceCreate, Workspaces, WorkspaceUpdate } from "@s6e/spicify-api-sdk-js";
import api from "@s6e/spicify-api-sdk-js";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { extractErrors } from "store/common";

interface UpdateWorkspaceParams {
	workspaceId: number,
	data: WorkspaceUpdate
}

const cacheType: "Workspace" = "Workspace";
export const workspaceApi = createApi({
	reducerPath: 'workspaceApi',
	baseQuery: fakeBaseQuery(),
	tagTypes: [cacheType],
	endpoints: (builder) => ({
		getWorkspaces: builder.query<Workspaces, void>({
			queryFn: async (_) => {
				try {
					const workspaces = await api.workspaces.getWorkspaces();
					return { data: workspaces }
				} catch (error) {
					return extractErrors(error);
				}
			},
			providesTags: (result = []) => [
				cacheType,
				...result.map(({ id }) => ({ type: cacheType, id }))
			]
		}),
		getWorkspace: builder.query<Workspace, number>({
			queryFn: async (workspaceId) => {
				try {
					const workspace = await api.workspaces.getWorkspace(workspaceId);
					return { data: workspace }
				} catch (error) {
					return extractErrors(error);
				}
			},
			providesTags: (result) => result ? [{ type: cacheType, id: result.id }] : [],
		}),
		createWorkspace: builder.mutation<Workspace, WorkspaceCreate>({
			queryFn: async (data: WorkspaceCreate) => {
				try {
					const workspace = await api.workspaces.createWorkspace(data);
					return { data: workspace }
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: [{ type: cacheType }]
		}),
		updateWorkspace: builder.mutation<Workspace, UpdateWorkspaceParams>({
			queryFn: async (params: UpdateWorkspaceParams) => {
				try {
					const { workspaceId, data } = params;
					const workspace = await api.workspaces.updateWorkspace(workspaceId, data);
					return { data: workspace };
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: (result) => result ? [
				cacheType,
				{ type: cacheType, id: result.id }
			] : []
		}),
		deleteWorkspace: builder.mutation<number, number>({
			queryFn: async (workspaceId: number) => {
				try {
					await api.workspaces.deleteWorkspace(workspaceId);
					return { data: workspaceId };
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: (result) => result ? [
				cacheType,
				{ type: cacheType, id: result }
			] : []
		}),
	}),
});

export const {
	useGetWorkspacesQuery,
	useGetWorkspaceQuery,
	useCreateWorkspaceMutation,
	useUpdateWorkspaceMutation,
	useDeleteWorkspaceMutation,
} = workspaceApi