import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import api, { GenerateQuery, Ranking } from "@s6e/spicify-api-sdk-js";
import { extractErrors } from "store/common";

type FetchRankingParams = {
	workspaceId: number,
	query: GenerateQuery
}

export const generatorApi = createApi({
	reducerPath: 'generatorApi',
	baseQuery: fakeBaseQuery(),
	endpoints: (builder) => ({
		getRanking: builder.query<Ranking, FetchRankingParams>({
			queryFn: async (params) => {
				try {
					const { workspaceId, query } = params;
					const ranking = await api.generator.getRanking(workspaceId, query);
					return { data: ranking }
				} catch (error) {
					return extractErrors(error);
				}
			},
		}),
	}),
});

export const {
	useGetRankingQuery
} = generatorApi