import { FunctionComponent } from "react";
import { Dish, Dishes } from "@s6e/spicify-api-sdk-js";
import {
	Button,
	Paper,
	styled,
	Typography,
} from "@mui/material";

import DishCard from "./DishCard";
import { injectIntl, WrappedComponentProps } from "react-intl";

type DishListProps = {
	dishes: Dishes;
	onDishNameClick?: (dish: Dish) => void;
	onDishFavoriteClick?: (dish: Dish) => void;
	onDishUseClick?: (dish: Dish) => void;
	onDishDropClick?: (dish: Dish) => void;
	onDishEditClick?: (dish: Dish) => void;
	onDishDeleteClick?: (dish: Dish) => void;
	onAddDishClick?: () => void;
};

const CardList = styled('div')({
})

const DishList: FunctionComponent<DishListProps & WrappedComponentProps> = ({
	dishes,
	onDishNameClick = undefined,
	onDishFavoriteClick = undefined,
	onDishUseClick = undefined,
	onDishDropClick = undefined,
	onDishEditClick = undefined,
	onDishDeleteClick = undefined,
	onAddDishClick = undefined,
	intl
}) => {

	return (
		<>
			<CardList>
				{dishes.map((dish: Dish) => {
					return (
						<DishCard
							key={dish.id}
							dish={dish}
							onNameClick={() => {
								if (onDishNameClick)
									onDishNameClick(dish); 
							}}
							onFavoriteClick={() => {
								if (onDishFavoriteClick)
									onDishFavoriteClick(dish)
							}}
							onUseClick={() => {
								if (onDishUseClick)
									onDishUseClick(dish)
							}}
							onDropClick={() => {
								if (onDishDropClick)
									onDishDropClick(dish)
							}}
							onEditClick={() => {
								if (onDishEditClick)
									onDishEditClick(dish)
							}}
							onDeleteClick={() => {
								if (onDishDeleteClick)
									onDishDeleteClick(dish)
							}}
						/>
					);
				})}
			</CardList>
			<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, margin: 1 }} elevation={3}>
				{dishes.length === 0 && 
					<Typography align="center" sx={{ margin: 1 }} variant="body1">
						{intl.formatMessage({ id: "app.emptyDishes" })}
					</Typography>}
				{onAddDishClick && (<Button
					fullWidth
					color="primary"
					variant="contained"
					onClick={onAddDishClick}
					sx={{ marginTop: 1 }}
				>
					{intl.formatMessage({ id: "app.addDish" })}
				</Button>)}
			</Paper>
		</>
	);
};

export default injectIntl(DishList);
