import { TimeEffort } from "@s6e/spicify-api-sdk-js"
import { FunctionComponent } from "react"
import DishIconLong from "./DishIconLong"
import DishIconMedium from "./DishIconMedium"
import DishIconShort from "./DishIconShort"

export type DishTimeIconProps = {
    timeEffort: TimeEffort
}

const DishTimeIcon: FunctionComponent<DishTimeIconProps> = ({
	timeEffort
}) => {
    if (timeEffort === TimeEffort.Long) {
        return <DishIconLong timeEffort={timeEffort} />
    }

    if (timeEffort === TimeEffort.Medium) {
        return <DishIconMedium timeEffort={timeEffort} />
    }

    return <DishIconShort timeEffort={timeEffort} />
};

export default DishTimeIcon;