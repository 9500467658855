import { Fragment, FunctionComponent } from "react";
import { Typography, IconButton, Divider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAccessTransId } from "helpers/share";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Invitations } from "@s6e/spicify-api-sdk-js";
import { StyledListItem } from "theme/styledComponents";

type InvitationListProps = {
	invitations: Invitations;
	setChoosenInvitationId: (invitationId: number) => void;
	setEditInvitationDialogOpened: (isOpen: boolean) => void;
	setDeleteInvitationDialogOpened: (isOpen: boolean) => void;
};

const InvitationList: FunctionComponent<
	InvitationListProps & WrappedComponentProps
> = ({
	intl,
	invitations,
	setChoosenInvitationId,
	setEditInvitationDialogOpened,
	setDeleteInvitationDialogOpened
}) => {
	return (
		<ul>
			{invitations.map((invitation) => {
				return (
					<Fragment key={invitation.id}>
						<StyledListItem>
							<div
								style={{
									display: "flex",
									width: "55%"
								}}
							>
								<Typography variant="subtitle2" noWrap>
									{invitation.email}
								</Typography>
							</div>

							<Typography variant="subtitle2">
								{intl.formatMessage({
									id: getAccessTransId(
										invitation.access_level
									)
								})}
							</Typography>

							<div>
								<IconButton
									onClick={() => {
										setChoosenInvitationId(invitation.id);
										setEditInvitationDialogOpened(true);
									}}
								>
									<EditIcon color="primary" />
								</IconButton>
								<IconButton
									onClick={() => {
										setChoosenInvitationId(invitation.id);
										setDeleteInvitationDialogOpened(true);
									}}
								>
									<DeleteIcon color="secondary" />
								</IconButton>
							</div>
						</StyledListItem>
						<Divider />
					</Fragment>
				);
			})}
		</ul>
	);
};

export default injectIntl(InvitationList);
