import { FunctionComponent } from "react"
import { DishIconProps } from "./DishIconProps";

const DishIconShort: FunctionComponent<DishIconProps> = ({
    color = '#4a1971',
    width = 35,
    height = 35,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 41" width={width} height={height}>
      <g data-name="Group 29" transform="translate(-40 -498)">
        <path
          fill={color}
          d="M72 521H58.7v-13a13.423 13.423 0 015.188 1.112 13.467 13.467 0 014.23 2.9 13.463 13.463 0 012.849 4.271A13.408 13.408 0 0172 521z"
          data-name="Subtraction 2"
        ></path>
        <g
          fill="none"
          stroke={color}
          strokeWidth="2"
          data-name="Ellipse 2"
          transform="translate(40 504)"
        >
          <ellipse cx="18" cy="17.5" stroke="none" rx="18" ry="17.5"></ellipse>
          <ellipse cx="18" cy="17.5" rx="17" ry="16.5"></ellipse>
        </g>
        <rect
          width="10"
          height="4"
          fill={color}
          data-name="Rectangle 66"
          rx="2"
          transform="translate(53 498)"
        ></rect>
        <rect
          width="4.836"
          height="2.046"
          fill={color}
          data-name="Rectangle 68"
          rx="1.023"
          transform="rotate(-30 965.95 169.401)"
        ></rect>
        <rect
          width="4.836"
          height="2.046"
          fill={color}
          data-name="Rectangle 72"
          rx="1.023"
          transform="rotate(30 -905.585 376.619)"
        ></rect>
        <path
          fill={color}
          d="M0 0H4V4H0z"
          data-name="Rectangle 67"
          transform="translate(56 502)"
        ></path>
        <path
          fill={color}
          d="M0 0H2.554V2.554H0z"
          data-name="Rectangle 69"
          transform="rotate(-30 968.526 166.278)"
        ></path>
        <path
          fill={color}
          d="M0 0H2.554V2.554H0z"
          data-name="Rectangle 71"
          transform="rotate(-150 102.247 245.247)"
        ></path>
      </g>
    </svg>
  );
}

export default DishIconShort;