import { IntlShape } from "react-intl";

export const errorString = (intl: IntlShape, ...errors: unknown[]): string | undefined => {
    for (const error of errors) {
        if (error === undefined) {
            continue;
        }

        if (error === null) {
            continue;
        }

        if (typeof error === "string") {
            return error;
        }

        return intl.formatMessage({ id: "app.somethingWentWrong" })
    }

    return undefined;
}