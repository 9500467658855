import { useState, useEffect, FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import {
	injectIntl,
	WrappedComponentProps
} from "react-intl";
import ListHeaderSection from "components/common/ListHeaderSection/ListHeaderSection";
import { Workspace } from "@s6e/spicify-api-sdk-js";
import { pages } from "pages";
import WorkspaceDeleteDialog from "./WorkspaceDeleteDialog";
import ContentLoader from "components/common/ContentPage/ContentLoader";
import { useDeleteWorkspaceMutation, useGetWorkspacesQuery } from "../../../store/apis/workspace";
import { errorString } from "../../../helpers/api";


const WorkspacesPage: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const navigate = useNavigate();

	const [isDialogOpened, setDialogOpened] = useState(false);
	const [workspaceToDelete, setWorkspaceToDelete] =
		useState<Workspace | null>(null);

	const { data: workspaces, isLoading, error } = useGetWorkspacesQuery();
	const [
		deleteWorkspace,
		{
			isSuccess: isWorkspaceDeleteSuccess,
			isError: isWorkspaceDeleteError,
			error: workspaceDeleteError,
			isLoading: isWorkspaceDeleteInProgress
		}
	] = useDeleteWorkspaceMutation()


	const onWorkspaceClick = async (workspaceId: number) => {
		navigate(pages.workspace.url(workspaceId));
	};

	const renderListItems = () => {
		if (workspaces) {
			return (
				<>
					{workspaces.map((workspace: Workspace) => {
						return (
							<ListItem key={workspace.id} divider>
								<ListItemText
									primary={workspace.name}
									onClick={() => onWorkspaceClick(workspace.id)}
									style={{
										cursor: "pointer",
										flex: "inherit",
										wordBreak: "break-word"
									}}
								/>

								<ListItemSecondaryAction>
									{!workspace.personal && (
										<>
											<IconButton
												component={Link}
												to={pages.workspace.share.url(workspace.id)}
											>
											<ShareIcon color="primary" />
												</IconButton>
											<IconButton
												component={Link}
												to={pages.workspace.edit.url(workspace.id)}
											>
												<EditIcon color="primary" />
											</IconButton>
											<IconButton
												onClick={() => {
													setWorkspaceToDelete(workspace);
													setDialogOpened(true);
												}}
											>
												<DeleteIcon color="secondary" />
											</IconButton>
										</>
									)}
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</>
			);
		} else {
			return (
				<Typography variant="h5">
					{intl.formatMessage({ id: "app.emptyWorkspaces" })}
				</Typography>
			);
		}
	};

	const onDeleteWorkspaceClick = async () => {
		if (workspaceToDelete) {
			deleteWorkspace(workspaceToDelete.id);
		}
	};

	useEffect(() => {
		if (isWorkspaceDeleteSuccess || isWorkspaceDeleteError) {
			closeDialog();
		}
	}, [ isWorkspaceDeleteSuccess, isWorkspaceDeleteError ]);

	useEffect(() => {
		if (workspaceDeleteError) {
			toast.error(errorString(intl, workspaceDeleteError));
		}
	}, [ workspaceDeleteError, intl ]);

	const closeDialog = () => {
		setDialogOpened(false);
	};

	return (
		<div>
			<ListHeaderSection
				pageTitle={intl.formatMessage({ id: "app.workspaces" }) + ":"}
				btnHref={pages.workspaceCreate.url()}
				btnText={intl.formatMessage({ id: "app.create" })}
			/>
			<ContentLoader
				error={errorString(intl, error)}
				loading={isLoading}
			>
				<List>{renderListItems()}</List>
				{workspaceToDelete && 
					(<WorkspaceDeleteDialog 
						isOpen={isDialogOpened}
						deleteInProgress={isWorkspaceDeleteInProgress}
						onClose={closeDialog}
						workspace={workspaceToDelete}
						onDelete={onDeleteWorkspaceClick}
					/>)}
			</ContentLoader>
		</div>
	);
};

export default injectIntl(WorkspacesPage);
