import { ShareLink, ShareLinkCreate, ShareLinks, ShareLinkUpdate } from "@s6e/spicify-api-sdk-js";
import api from "@s6e/spicify-api-sdk-js";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { extractErrors } from "store/common";

interface ShareLinkParams {
	workspaceId: number;
	shareLinkId: number;
}

interface CreateShareLinkParams {
	workspaceId: number,
	data: ShareLinkCreate
}

interface UpdateShareLinkParams {
	workspaceId: number,
	shareLinkId: number,
	data: ShareLinkUpdate
}

const cacheType: "ShareLink" = "ShareLink";
export const shareLinkApi = createApi({
	reducerPath: 'shareLinkApi',
	baseQuery: fakeBaseQuery(),
	tagTypes: [cacheType],
	endpoints: (builder) => ({
		getShareLinks: builder.query<ShareLinks, number>({
			queryFn: async (workspaceId) => {
				try {
					const shareLinks = await api.shareLinks.getShareLinks(workspaceId);
					return { data: shareLinks }
				} catch (error) {
					return extractErrors(error);
				}
			},
			providesTags: (result = []) => [
				'ShareLink',
				...result.map(({ id }) => ({ type: 'ShareLink' as const, id }))
			]
		}),
		getShareLink: builder.query<ShareLink, ShareLinkParams>({
			queryFn: async (params) => {
				try {
					const { workspaceId, shareLinkId } = params;
					const shareLink = await api.shareLinks.getShareLink(workspaceId, shareLinkId);
					return { data: shareLink }
				} catch (error) {
					return extractErrors(error);
				}
			},
		}),
		createShareLink: builder.mutation<ShareLink, CreateShareLinkParams>({
			queryFn: async (params: CreateShareLinkParams) => {
				try {
					const { workspaceId, data } = params;
					const shareLink = await api.shareLinks.createShareLink(workspaceId, data);
					return { data: shareLink }
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: [{ type: 'ShareLink' }]
		}),
		updateShareLink: builder.mutation<ShareLink, UpdateShareLinkParams>({
			queryFn: async (params: UpdateShareLinkParams) => {
				try {
					const { workspaceId, shareLinkId, data } = params;
					const shareLink = await api.shareLinks.updateShareLink(workspaceId, shareLinkId, data);
					return { data: shareLink };
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: [{ type: 'ShareLink' }]
		}),
		deleteShareLink: builder.mutation<number, ShareLinkParams>({
			queryFn: async (params: ShareLinkParams) => {
				const { workspaceId, shareLinkId } = params;
				try {
					await api.shareLinks.deleteShareLink(workspaceId, shareLinkId);
					return { data: shareLinkId };
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: [{ type: 'ShareLink' }]
		}),
	}),
});

export const {
	useGetShareLinksQuery,
	useGetShareLinkQuery,
	useCreateShareLinkMutation,
	useUpdateShareLinkMutation,
	useDeleteShareLinkMutation,
} = shareLinkApi