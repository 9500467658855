import { CircularProgress } from "@mui/material";
import { useAppSelector } from "hooks";
import { pages } from "pages";
import { FunctionComponent, ReactElement } from "react";
import { Navigate } from "react-router-dom";

type ProtectedRouteProps = {
	reverse?: boolean;
	children?: ReactElement<any, any> | null;
};

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
	reverse = false,
	children = null
}) => {
	const isAuthenticated = useAppSelector(
		(state) => state.auth.isAuthenticated
	);

	if (isAuthenticated === null) {
		return <CircularProgress style={{ marginTop: 10 }} />;
	}

	if (reverse && isAuthenticated) {
		return <Navigate to={pages.main.url()} replace />;	
	}

	if (!reverse && !isAuthenticated) {
	   return <Navigate to={pages.login.url()} replace />;
	}
  
	return children;
  };

export default ProtectedRoute;
