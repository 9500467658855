import { FunctionComponent, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { ButtonsWrapper } from "theme/styledComponents";
import { TextField, Button } from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ContentPage from "components/common/ContentPage/ContentPage";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import SubmitButton from "components/common/SubmitButton";
import { toast } from "react-toastify";
import { errorString } from "helpers/api";
import {
	useCreateWorkspaceMutation,
	useGetWorkspaceQuery,
	useUpdateWorkspaceMutation
} from "../../../../store/apis/workspace";

interface WorkspaceFormProps {
	workspaceId?: number;
}

const WorkspaceForm: FunctionComponent<WorkspaceFormProps & WrappedComponentProps> = (
	{ workspaceId, intl }) => {
	const navigate = useNavigate();

	const { data: workspace, isLoading, error } = useGetWorkspaceQuery(workspaceId || 0, {
		skip: !workspaceId
	});
	const [ updateWorkspace,
		{
			isSuccess: isWorkspaceUpdateSuccess,
			isLoading: isWorkspaceUpdateInProgress,
			error: workspaceUpdateError
		}
	] = useUpdateWorkspaceMutation();
	const [ createWorkspace,
		{
			isSuccess: isWorkspaceCreateSuccess,
			isLoading: isWorkspaceCreateInProgress,
			error: workspaceCreateError
		}
	] = useCreateWorkspaceMutation();

	useEffect(() => {
		if (isWorkspaceCreateSuccess || isWorkspaceUpdateSuccess) {
			navigate(pages.workspaces.url());
		}
	}, [ isWorkspaceCreateSuccess, isWorkspaceUpdateSuccess, navigate ])


	const onSubmit = async ({ workspaceName }: FieldValues) => {
		const data = {
			name: workspaceName
		};

		if (workspaceId)
			updateWorkspace({
				workspaceId,
				data
			});
		else {
			createWorkspace(data);
		}
	};

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		defaultValues: {
			workspaceName: workspace?.name || ""
		}
	});

	useEffect(() => {
		if (workspaceUpdateError) {
			toast.error(errorString(intl, workspaceUpdateError));
		}
	}, [ workspaceUpdateError, intl ])

	if (error) {
		return <ContentError error={errorString(intl, error)} />
	}

	return (
		<ContentPage
			title={
				workspaceId
					? intl.formatMessage({ id: "app.editWorkspace" })
					: intl.formatMessage({ id: "app.addWorkspace" })
			}
			closeLink={pages.workspaces.url()}
			loading={isLoading}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.name" })}
					{...register("workspaceName", { required: true })}
					name="workspaceName"

					helperText={errors.workspaceName?.message?.toString() || ''}
					error={!!errors.workspaceName}
					style={{ marginTop: 10 }}
				/>
				<ContentError error={errorString(intl, workspaceCreateError, workspaceUpdateError)} />
				<ButtonsWrapper>
					<Button
						color="secondary"
						variant="contained"
						component={Link}
						disabled={isWorkspaceCreateInProgress || isWorkspaceUpdateInProgress}
						to={pages.workspaces.url()}
						style={{ marginRight: 10 }}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton
						caption={intl.formatMessage({ id: "app.save" })}
						inProgress={isWorkspaceCreateInProgress || isWorkspaceUpdateInProgress}
						/>
				</ButtonsWrapper>
			</form>
		</ContentPage>
	);
};

export default injectIntl(WorkspaceForm);
