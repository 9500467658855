import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Workspace } from "@s6e/spicify-api-sdk-js";
import DeleteDialog from "components/common/DeleteDialog";

interface WorkspaceDeleteDialogProps {
	workspace: Workspace;
	isOpen: boolean;
	deleteInProgress: boolean;
	onClose: () => void;
	onDelete: () => void;
}

const WorkspaceDeleteDialog: FunctionComponent<WorkspaceDeleteDialogProps> = ({
	workspace,
	isOpen,
	deleteInProgress,
	onClose,
	onDelete
}) => {
	return (
		<DeleteDialog
			isOpen={isOpen}
			onClose={onClose}
			deleteInProgress={deleteInProgress}
			message={
				<FormattedMessage
					id="app.confirmWorkspaceDelete"
					values={{
						workspaceName: <strong>"{workspace.name}"</strong>
					}}
				/>
			}
			onDelete={onDelete}
		/>
	);
};

export default WorkspaceDeleteDialog;
