import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { toast } from "react-toastify";
import {
	FormControlLabel,
	Checkbox,
	FormControl,
	FormHelperText
} from "@mui/material";
import Logo from "components/common/Logo/Logo";
import TextWithLink from "components/common/TextWithLink/TextWithLink";
import LoadingButton from "components/common/LoadingButton/LoadingButton";
import { StyledTextField } from "theme/styledComponents";
import { pages } from "pages";

import ContentError from "components/common/ContentPage/ContentError";
import { errorString } from "helpers/api";

import "./register.css";
import { useRegisterUserMutation } from "../../../store/apis/auth";
import { confirmPasswordValidation, emailValidation, passwordValidation } from "../../../validators";

type RegisterFormSubmit = (payload: FieldValues) => Promise<void>;

const minUsernameLength = 3;
const Register: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		watch
	} = useForm();

	const navigate = useNavigate();

	const [ registerUser, { isLoading, error, isSuccess }] = useRegisterUserMutation();

	const submitForm: RegisterFormSubmit = async ({
		username,
		email,
		password,
		terms
	}) => {
		const newUser = {
			username,
			email,
			plain_password: password,
			agree_terms: terms
		};
		registerUser(newUser);
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success(
				`🙌 ${intl.formatMessage({ id: "app.successRegister" })}`
			);
			navigate(pages.login.url());
		}
	}, [ isSuccess, navigate, intl ]);

	return (
		<div className="registration-wrapper">
			<Logo />
			<form
				className="registration-form"
				onSubmit={handleSubmit(submitForm)}
			>
				<StyledTextField
					label={intl.formatMessage({ id: "app.username" })}
					{...register("username", {
						required: intl.formatMessage({ id: "app.usernameIsRequired" }),
						minLength: {
							value: minUsernameLength,
							message: intl.formatMessage({ id: "app.minUsernameLength" }, { "minLength": minUsernameLength })
						}
					})}
					helperText={errors.username?.message?.toString() || ''}
					error={!!errors.username}
				/>
				<StyledTextField
					label={intl.formatMessage({ id: "app.email" })}
					{...register("email", emailValidation(intl))}
					helperText={errors.email?.message?.toString() || ''}
					error={!!errors.email}
				/>
				<StyledTextField
					label={intl.formatMessage({ id: "app.password" })}
					type="password"
					{...register("password", passwordValidation(intl))}
					helperText={errors.password?.message?.toString() || ''}
					error={!!errors.password}
				/>
				<StyledTextField
					label={intl.formatMessage({ id: "app.confirmPassword" })}
					type="password"
					{...register("confirmPassword", confirmPasswordValidation(intl, watch))}
					helperText={errors.confirmPassword?.message?.toString() || ''}
					error={!!errors.confirmPassword}
				/>
				<FormControl error={!!errors.terms}>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								{...register("terms",
									{
										required: intl.formatMessage({ id: "app.termsRequired" })
									})}
							/>
						}
						label={
							<>
								<span>Accept</span>
								<a
									rel="noopener noreferrer"
									target="_blank"
									href="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies"
									style={{ marginLeft: 5 }}
								>
									Terms of use
								</a>
							</>
						}
						sx={{
							fontsize: '0.875rem'
						}}
					/>
					{errors.terms && (
						<FormHelperText>{errors.terms?.message?.toString() || ''}</FormHelperText>
					)}
				</FormControl>

				<ContentError error={errorString(intl, error)} />
				<LoadingButton
					isLoading={isLoading}
					size={25}
					type="submit"
					label={intl.formatMessage({ id: "app.signUp" })}
				/>
			</form>

			<TextWithLink
				text={intl.formatMessage({ id: "app.alreadyHaveAccount" })}
				linkHref={pages.login.url()}
				buttonText={intl.formatMessage({ id: "app.signIn" })}
			/>
		</div>
	);
};

export default injectIntl(Register);
