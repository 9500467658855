import { FunctionComponent, useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ButtonsWrapper } from "theme/styledComponents";
import {
	TextField,
	Button,
	Chip,
	Typography
} from "@mui/material";
import ContentPage from "components/common/ContentPage/ContentPage";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { pages } from "pages";
import SubmitButton from "components/common/SubmitButton";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { getNumberParam } from "helpers/views";
import { useCreateTagMutation, useGetTagQuery, useUpdateTagMutation } from "../../../../store/apis/tag";
import { errorString } from "../../../../helpers/api";



type TagFormParams = {
	tagId: string;
}

type TagFormSubmitPayload = {
	tagName: string;
	tagFontColor: string;
	tagBackgroundColor: string;
	tagGroup?: string;
};

const tagDefaultValues: TagFormSubmitPayload = {
	tagName: "",
	tagGroup: "",
	tagFontColor: "#ffffff",
	tagBackgroundColor: "#000000"
};

const chipDefaultValues: TagFormSubmitPayload = {
	tagName: tagDefaultValues.tagName || "preview",
	tagFontColor: tagDefaultValues.tagFontColor,
	tagBackgroundColor: tagDefaultValues.tagBackgroundColor,
	tagGroup: tagDefaultValues.tagGroup
};

type TagFormSubmit = (payload: TagFormSubmitPayload) => void;

const TagForm: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ 
	workspaceId,
	intl
}) => {
	const [chipPreviewValues, setChipPreviewValues] = useState(chipDefaultValues);
	const params = useParams<TagFormParams>();
	const [ tagId, paramError ] = getNumberParam(params.tagId, "tagId", intl);

	
	const navigate = useNavigate();

	const { data: tag, isLoading, error } = useGetTagQuery({  workspaceId, tagId: tagId || 0 }, {
		skip: !tagId
	});
	const [ updateTag,
		{
			isSuccess: isTagUpdateSuccess,
			isLoading: isTagUpdateInProgress
		}
	] = useUpdateTagMutation();
	const [ createTag,
		{
			isSuccess: isTagCreateSuccess,
			isLoading: isTagCreateInProgress
		}
	] = useCreateTagMutation();

	const {
		register,
		handleSubmit,
		reset,
		getValues,
		formState: { errors }
	} = useForm<TagFormSubmitPayload>({
		defaultValues: tagDefaultValues
	});

	const onSubmit: TagFormSubmit = async ({
		tagName,
		tagFontColor,
		tagBackgroundColor,
		tagGroup
	}) => {
		const data = {
			name: tagName,
			background_color: tagBackgroundColor.toUpperCase(),
			font_color: tagFontColor.toUpperCase(),
			group: tagGroup || "",
			is_system: false
		};

		if (tagId) {
			updateTag({
				workspaceId: workspaceId,
				tagId,
				data
			});
		} else {
			createTag({
				workspaceId: workspaceId,
				data
			});
		}

	};

	useEffect(() => {
		if (isTagUpdateSuccess || isTagCreateSuccess) {
			navigate(pages.workspace.tags.url(workspaceId));
		}
	}, [ isTagUpdateSuccess, isTagCreateSuccess, navigate, workspaceId ]);

	const updateChipPreview = () => {
		const p: TagFormSubmitPayload = {
			tagName: getValues("tagName") || chipDefaultValues.tagName,
			tagFontColor: getValues("tagFontColor"),
			tagBackgroundColor: getValues("tagBackgroundColor")
		};
		setChipPreviewValues(p);
	};

	useEffect(() => {
		if (tag) {
			reset({
				tagName: tag.name,
				tagFontColor: tag.font_color,
				tagBackgroundColor: tag.background_color,
				tagGroup: tag.group,
			});
			updateChipPreview();
		}
	}, [ tag ]);

	return (
		<ContentPage
			title={
				tagId
					? intl.formatMessage({ id: "app.editTag" })
					: intl.formatMessage({ id: "app.addTag" })
			}
			closeLink={pages.workspace.tags.url(workspaceId)}
			loading={isLoading}
			error={errorString(intl, paramError, error)}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<TextField
					fullWidth
					{...register("tagName", {
						required: true,
						onChange: () => updateChipPreview(),
					})}
					label={intl.formatMessage({ id: "app.name" })}
					helperText={errors.tagName?.message?.toString() || ''}
					error={!!errors.tagName}
					style={{ marginTop: 10 }} />
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.group" })}
					{...register("tagGroup")}

					helperText={errors.tagGroup?.message?.toString() || ''}
					error={!!errors.tagGroup}
					style={{ marginTop: 10 }}
				/>
				<div key="app.font_color" style={{ marginTop: "0.8rem" }}>
					<p>{intl.formatMessage({ id: "app.font_color" })}</p>
					<input
						type="color"
						{...register("tagFontColor", {
							required: true,
							onChange: () => updateChipPreview(),
						})}
						style={{ marginTop: 10, width: "100%" }} />
				</div>
				<div key="app.background_color" style={{ marginTop: "0.8rem" }}>
					<p>{intl.formatMessage({ id: "app.background_color" })}</p>
					<input
						type="color"
						{...register("tagBackgroundColor", {
							required: true,
							onChange: () => updateChipPreview(),
						})}
						style={{ marginTop: 10, width: "100%" }} />
				</div>
				<div style={{ marginTop: "0.8rem" }}>
					<p>{intl.formatMessage({ id: "app.preview" })}</p>
					<Chip
						label={
							<Typography
								variant="caption"
								style={{
									whiteSpace: "normal"
								}}
							>
								{chipPreviewValues.tagName}
							</Typography>
						}
						color="secondary"
						style={{
							color: chipPreviewValues.tagFontColor,
							backgroundColor:
								chipPreviewValues.tagBackgroundColor,
							marginTop: "0.8rem"
						}}
					/>
				</div>
				<ButtonsWrapper>
					<Button
						color="secondary"
						variant="contained"
						component={Link}
						to={pages.workspace.tags.url(workspaceId)}
						style={{ marginRight: 10 }}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton
						caption={intl.formatMessage({ id: "app.save" })}
						inProgress={isTagUpdateInProgress || isTagCreateInProgress}
					/>
				</ButtonsWrapper>
			</form>
		</ContentPage>
	);
};

export default injectIntl(TagForm);
