import { Fragment, FunctionComponent } from "react";
import { Typography, IconButton, Divider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { getAccessTransId } from "helpers/share";
import { ShareLinks } from "@s6e/spicify-api-sdk-js";
import { StyledListItem } from "theme/styledComponents";

type ShareLinkListProps = {
	shareLinks: ShareLinks;
	setChoosenLinkId: (shareLinkId: number) => void;
	setEditDialogOpened: (isOpen: boolean) => void;
	setDeleteDialogOpened: (isOpen: boolean) => void;
};

const ShareLinkList: FunctionComponent<
	ShareLinkListProps & WrappedComponentProps
> = ({
	intl,
	shareLinks,
	setChoosenLinkId,
	setEditDialogOpened,
	setDeleteDialogOpened
}) => {
	return (
		<ul>
			{shareLinks.map((shareLink) => {
				return (
					<Fragment key={shareLink.id}>
						<StyledListItem>
							<div
								style={{
									display: "flex",
									width: "55%"
								}}
							>
								<FilterNoneIcon />
								<a
									href={`${window.origin}/api/v1/share/${shareLink.hash}`}
									rel="noopener noreferrer"
									target="_blank"
									style={{
										marginLeft: "0.5rem",
										width: "80%"
									}}
								>
									<Typography variant="subtitle2" noWrap>
										{window.origin}
										/api/v1/share/
										{shareLink.hash}
									</Typography>
								</a>
							</div>

							<Typography variant="subtitle2">
								{intl.formatMessage({
									id: getAccessTransId(shareLink.access_level)
								})}
							</Typography>

							<div>
								<IconButton
									onClick={() => {
										setChoosenLinkId(shareLink.id);
										setEditDialogOpened(true);
									}}
								>
									<EditIcon color="primary" />
								</IconButton>
								<IconButton
									onClick={() => {
										setChoosenLinkId(shareLink.id);
										setDeleteDialogOpened(true);
									}}
								>
									<DeleteIcon color="secondary" />
								</IconButton>
							</div>
						</StyledListItem>
						<Divider />
					</Fragment>
				);
			})}
		</ul>
	);
};

export default injectIntl(ShareLinkList);
